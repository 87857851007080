import React from 'react'
import { FormattedMessage } from 'react-intl'
import { locales } from '../languages'
import Layout from '../components/Layout'
import Link from '../components/LocalizedLink'
import withLoading from '../hocs/withLoading'

import styles from './styles-404.module.css'

const NotFoundPage = ({ '*': localizedSlug, loading }) => {
  const locale = localizedSlug.split('/')[0]

  if (!locales.includes(locale)) return null

  const slug = `/${localizedSlug
    .split('/')
    .slice(1)
    .join('/')}`

  return (
    <Layout pageContext={{ locale, slug }} loading={loading} location={this.props.location}>
      <div className={styles.background}>
        <div className={styles.root}>
          <div className={styles.notFound}>
            <FormattedMessage id="none" defaultMessage={"404"} /> 
          </div>
          <div className={styles.title}>
            <FormattedMessage id="none" defaultMessage={"Page not found"} /> 
          </div>
          <div className={styles.navigation}>
            <Link className={styles.button} to={'/'}>
              <FormattedMessage id ="pages.404.goBackHome" defaultMessage="Go back to homepage" />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default withLoading(NotFoundPage)
